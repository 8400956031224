import React from 'react'

const TextInput = ({ bigLabel, label, mainDivClass, inputStyle }) => {
    return (
        <div className={`flex flex-col ${mainDivClass}`}>
            <label htmlFor="" className={`text-sm ${bigLabel}`} >{label}</label>
            <input type="" className={`border border-primary rounded-md px-2 h-8 outline-none ${inputStyle}`} />
        </div>
    )
}

export default TextInput