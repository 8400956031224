import React from 'react'

const DateInput = ({ bigLabel, label, mainDivClass, inputStyle, type }) => {
    return (
        <div className={`flex items-center justify-between mt-3  ${mainDivClass}`}>
            <label htmlFor="" className={`text-sm ${bigLabel}`} >{label}</label>
            <input type={type} className={`border border-primary rounded-md px-2 h-8 outline-none ${inputStyle}`} />

        </div>
    )
}

export default DateInput