import React from 'react'

const StickyFooter = ({backHandler,nextHandler,progress,totalSteps}) => {
    return (
        <div className="fixed bottom-0 right-0 left-0 bg-white pt-1">
            <div className="h-2 w-full bg-neutral-200">
                <div className="h-2 rounded-r-lg bg-primary transition-all duration-300" style={{ width: `${progress / totalSteps * 100}%` }} />
            </div>
            <div className="ml-auto flex w-fit my-5 gap-2 px-5">
                <button onClick={backHandler} className="text-base bg-lime-500 px-5 py-1 text-white rounded-lg">
                    Back
                </button>
                <button onClick={nextHandler} className="text-base bg-primary px-5 py-1 text-white black rounded-lg">
                    Next
                </button>
            </div>
        </div>
    )
}

export default StickyFooter