import React from 'react'

export const CameraInput = ({ buttonStyle, startCameraWithZoom, toggleCamera, videoContainerStyle, videoRef, zoomScale, stream, capturePhoto, handleZoom, imageList, deleteImage }) => {
    return (
        <div>
            <div className="my-5">
                <div>
                    <div className="my-3">
                        <button className={buttonStyle} onClick={startCameraWithZoom}>Start Camera</button>
                        {stream &&
                            <>
                                <button className={buttonStyle} onClick={toggleCamera}>Toggle Camera</button>
                            </>
                        }
                    </div>
                    <div style={videoContainerStyle}>
                        <video
                            ref={videoRef}
                            autoPlay
                            playsInline
                            width="100%"
                            height="100%"
                            style={{ transform: `scale(${zoomScale})`, transformOrigin: 'center center' }} // Adjust the scale here for soft zoom
                        ></video>
                    </div>
                    {stream && (
                        <div className="mt-3 flex justify-center">
                            <button className={buttonStyle} onClick={() => handleZoom(1)}>-</button>
                            <button className={buttonStyle} onClick={capturePhoto}>Capture Photo</button>
                            <button className={buttonStyle} onClick={() => handleZoom(2)}>+</button>
                        </div>
                    )}
                </div>
                <div className="!flex flex-row justify-center flex-wrap gap-5 mt-10">
                    {imageList.map((item, i) => {
                        return (
                            <div className='relative'>
                                <button
                                className="absolute bg-primary px-2 -right-2 -top-2 text-white rounded-full"
                                    // className={buttonStyle}
                                    onClick={() => deleteImage(item.id)}
                                >
                                    -
                                </button>
                                <img className="h-24 w-28" src={item.dataUri} alt="Captured" />
                            </div>
                        )
                    })
                    }
                </div>
            </div>
        </div>
    )
}
