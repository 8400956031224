import React, { useEffect, useRef, useState } from "react";
import TextInput from "../components/TextInput";
import DateInput from "../components/DateInput";
import StickyFooter from "../components/StickyFooter";
import { CameraInput } from "../components/CameraInput";

const Dashboard = () => {
  const videoContainerStyle = {
    overflow: 'hidden',
    width: '100%', // Set the width you want for the video here
    height: '100%', // Set the height you want for the video here
    position: 'relative', // Position relative to contain the absolute positioned video
  };
  const [progress, setProgress] = useState(0)
  console.log('progress: ', progress);
  const totalSteps = 3
  const videoRef = useRef(null);
  const [stream, setStream] = useState(null);
  const [cameraFacingMode, setCameraFacingMode] = useState('environment');
  const getCameraStream = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter((device) => device.kind === 'videoinput');

      if (videoDevices.length === 0) {
        console.error('No video input devices found.');
        return;
      }
      const constraints = {
        video: {
          facingMode: cameraFacingMode,
        },
      };
      const mediaStream = await navigator.mediaDevices.getUserMedia(constraints);
      setStream(mediaStream);

      if (videoRef.current) {
        videoRef.current.srcObject = mediaStream;
      }
    } catch (error) {
      console.error('Error accessing the camera', error);
    }
  };
  const [zoomScale, setZoomScale] = useState(1); // State to manage soft zoom scale

  // Function to increase soft zoom
  const increaseZoom = () => {
    setZoomScale(zoomScale + 0.1);
  };

  // Function to decrease soft zoom
  const decreaseZoom = () => {
    if (zoomScale > 1) {
      setZoomScale(zoomScale - 0.1);
    }
  };
  // Function to toggle between front and back cameras
  const toggleCamera = () => {
    setCameraFacingMode(cameraFacingMode === 'user' ? 'environment' : 'user');
    // Call getCameraStream again to switch to the selected camera
    getCameraStream();
  };

  const handleZoom = async (zoomValue) => {
    const track = stream.getVideoTracks()[0];
    const capabilities = track.getCapabilities();

    // Check if zoom is supported or not
    if (!('zoom' in capabilities)) {
      console.log('Zoom is not supported on this device, using soft zoom');

      // Apply soft zoom
      if (zoomValue > 1) {
        increaseZoom();
      } else {
        decreaseZoom();
      }
      return;
    }

    try {
      await track.applyConstraints({
        advanced: [{ zoom: zoomValue }]
      });
    } catch (error) {
      console.error('Applying zoom constraint failed', error);
    }
  };

  // Initiate the camera with zoom control
  const startCameraWithZoom = () => {
    getCameraStream({ video: { zoom: true } });
  };
  const [imageList, setImageList] = useState([])
  console.log('image: ', imageList);
  const capturePhoto = () => {
    if (videoRef.current) {
      const video = videoRef.current;
      const canvas = document.createElement('canvas');

      // Calculate the zoomed area
      const scale = zoomScale; // Make sure to use the current state for zoom scale
      const zoomedWidth = video.videoWidth / scale;
      const zoomedHeight = video.videoHeight / scale;
      const xStart = (video.videoWidth - zoomedWidth) / 2;
      const yStart = (video.videoHeight - zoomedHeight) / 2;

      // Set the canvas size to the zoomed size
      canvas.width = zoomedWidth;
      canvas.height = zoomedHeight;
      const context = canvas.getContext('2d');

      // Only draw the zoomed part of the video onto the canvas
      context.drawImage(video, xStart, yStart, zoomedWidth, zoomedHeight, 0, 0, canvas.width, canvas.height);

      // Convert the canvas content to a data URL representing the image
      const photoDataUrl = canvas.toDataURL('image/jpeg');
      // setImage(photoDataUrl)
      const newImage = { id: Date.now(), dataUri: photoDataUrl };

      // Save the image to the local state and local storage
      setImageList([...imageList, newImage]);
      localStorage.setItem('imageList', JSON.stringify([...imageList, newImage]));
      // You can now use 'photoDataUrl' for display or further processing
      // For example, you can display it in an <img> element or send it to a server.

      // Optionally, you can also save the data URL to a state variable for use elsewhere in your component.
    }
  };

  const clearLocalStorage = () => {
    // Clear the local storage and reset the state
    localStorage.removeItem('imageList');
    setImageList([]);
  };

  useEffect(() => {
    // Retrieve saved images from local storage on component load
    const savedImages = JSON.parse(localStorage.getItem('imageList'));
    if (savedImages) {
      setImageList(savedImages);
    }
  }, []);

  useEffect(() => {
    // Clear local storage on refresh (page reload)
    const handleBeforeUnload = () => {
      clearLocalStorage();
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
  const buttonStyle = "bg-primary text-white px-3 py-2 mr-3 rounded-md"
  const nextHandler = () => {
    if (progress < totalSteps) {
      setProgress(progress + 1)
    }
  }
  const backHandler = () => {
    if (progress > 0)
      setProgress(progress - 1)
  }
  const deleteImage = (id) => {
    const updatedImageList = imageList.filter(item => item.id !== id);
    setImageList(updatedImageList);
    localStorage.setItem('imageList', JSON.stringify(updatedImageList));
  };
  return (
    <div>

      <div className="md:w-[50%] border border-primary rounded-md shadow-lg py-2 px-5 mx-auto my-10 ">
        {progress === 0 ?
          <>
            <div className="flex justify-between items-end">
              <div className="text-2xl font-semibold text-primary">
                MSA Qwick Capture
              </div>
              <TextInput label={"MSAFile"} />
            </div>
            <div className="ml-auto flex w-fit my-5 gap-2">
              <button className="text-base bg-lime-500 px-5 py-1 text-white rounded-lg">
                Connect
              </button>
              <button className="text-base bg-primary px-5 py-1 text-white black rounded-lg">
                Submit
              </button>
            </div>
            <div className="flex flex-col">
              <label htmlFor="" className={` text-2xl text-red-600 mb-1 `}>
                {"Special Notes"}
              </label>
              <textarea
                className="h-32 rounded-md border border-primary  p-2 outline-none"
                // bigLabel={"text-2xl text-red-600 mb-1 "}
                label={"Special Notes"}
              />
            </div>
            <DateInput label={"Date of Loss"} />
            <div className={`flex items-center justify-between mt-3  `}>
              <label htmlFor="" className={`text-sm `}>
                {"Company Name"}
              </label>
              <select
                className={`border border-primary rounded-md px-2 h-8 w-[12.2rem] outline-none `}
                name="cars"
                id="cars"
              >
                <option value="volvo">Select name</option>
                <option value="saab">OPTION</option>
                <option value="mercedes">option</option>
                <option value="audi">option</option>
              </select>
            </div>
            <div className={`flex items-center justify-between mt-3  `}>
              <label htmlFor="" className={`text-sm `}>
                {"Inspector"}
              </label>
              <select
                className={`border border-primary rounded-md px-2 h-8 w-[12.2rem] outline-none `}
                name="cars"
                id="cars"
              >
                <option value="volvo">select inspector</option>
                <option value="saab">Miles Zambrano</option>
                <option value="mercedes">Michael Zambrano</option>
                <option value="audi">Alliott Green</option>

              </select>
            </div>
          </>
          : progress === 1 ?
            <div className="py-5">
              <h1 className="text-center bg-lime-400 w-fit px-4 py-1 rounded-lg mx-auto font-bold  text-white mb-5">
                Building Information
              </h1>
              <div className={`flex items-center justify-between mt-3  `}>
                <label htmlFor="" className={`text-sm `}>
                  {"Building Type"}
                </label>
                <select
                  className={`border border-primary rounded-md px-2 h-8 w-[12.2rem] outline-none `}
                  name="cars"
                  id="cars"
                >
                  <option value="volvo">select inspector</option>
                  <option value="saab">OPTION</option>
                  <option value="mercedes">option</option>
                  <option value="audi">option</option>
                </select>
              </div>
              <DateInput label={"Stories"} />
              <DateInput label={"Ground Floor Area"} />
              <DateInput label={"Total Footage Area"} />
            </div>


            : progress === 2 ?
              <>
                <div>
                  <div className={`flex items-center justify-between mt-3  `}>
                    <label htmlFor="" className={`text-sm `}>
                      {"Exterior :"}
                    </label>
                    <select
                      className={`border border-primary rounded-md px-2 h-8 w-[12.2rem] outline-none `}
                      name="cars"
                      id="cars"
                    >
                      <option value="saab">Front</option>
                      <option value="mercedes">Right</option>
                      <option value="audi">Rear</option>
                      <option value="audi">Left</option>
                    </select>
                  </div>
                  <DateInput
                    label={"Description (optional)"}
                    mainDivClass={"flex-col"}
                    inputStyle={"!w-full !items-start !h-10"}
                  />
                  <CameraInput {...{ buttonStyle, startCameraWithZoom, toggleCamera, videoContainerStyle, videoRef, zoomScale, stream, capturePhoto, handleZoom, imageList, deleteImage }} />

                </div>

              </> : progress === 3 ? <>
                <div>
                  <div className="flex flex-col">
                    <label htmlFor="" className={` text-2x mb-1 `}>
                      {"Contact Notes"}
                    </label>
                    <textarea
                      className="h-32 rounded-md border border-primary  p-2 outline-none"
                      // bigLabel={"text-2xl text-red-600 mb-1 "}
                      label={"Special Notes"}
                    />
                  </div>
                  <div className="py-5">
                    <DateInput type={"date"} label={"Contact Notes"} />
                    <DateInput type={"date"} label={"Date Received"} />
                    <DateInput type={"date"} label={"Due Date"} />
                    <DateInput label={"Policy Number "} />
                    <DateInput label={"Claim Number "} />
                    <DateInput label={"Insured (First)"} />
                    <DateInput label={"Insured (Last)"} />

                  </div>
                  <div className="py-5">
                    <h1 className="text-center bg-lime-400 w-fit px-4 py-1 rounded-lg mx-auto font-bold  text-white mb-5">
                      Physical address of loss
                    </h1>
                    <DateInput label={"Address 1"} />
                    <DateInput label={"Address 2"} />
                    <DateInput label={"City"} />
                    <DateInput label={"State"} />
                    <DateInput label={"Zipcode"} />
                  </div>

                </div>
                <div className="flex flex-col">
                  <label htmlFor="" className={` text-2xl mb-1 `}>
                    {"Special Notes"}
                  </label>
                  <textarea
                    className="h-44 rounded-md border border-primary  p-2 outline-none"
                    // bigLabel={"text-2xl text-red-600 mb-1 "}
                    label={"Quick Notes"}
                  />
                </div>
                <div className={`flex flex-col mt-3  `}>
                  <label htmlFor="" className={`text-sm text-center `}>
                    {"Room Type"}
                  </label>
                  <select
                    className={`border border-primary rounded-md px-2 h-10 w-full outline-none `}
                    name="cars"
                    id="cars"
                  >
                    <option value="volvo">Kitchen Room</option>
                    <option value="saab">OPTION</option>
                    <option value="mercedes">option</option>
                    <option value="audi">option</option>
                  </select>
                </div>
              </> : null}

      </div>
      <StickyFooter {...{ backHandler, nextHandler, progress, totalSteps }} />
    </div>
  );
};
export default Dashboard;
