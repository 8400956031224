import "./App.css";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./pages";
import 'react-tooltip/dist/react-tooltip.css'
// import Second from "./pages/second";
const App = () => {
  return (
    <>
      <Routes>
          <Route path={"/"} element={<Dashboard />} />
          {/* <Route path={"/second"} element={<Second />} /> */}
      </Routes>
    </>
  );
};
export default App;